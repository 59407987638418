import { Box, Flex, Text, Spacer, Link } from "@chakra-ui/layout";
import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router";
import {Heading, Button, Img} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import SEO from "../Components/SEO";
import { format, parseISO } from 'date-fns'








export default function PostPage() {
    const [postInfo, setPostInfo] = useState();
    const {id} = useParams();
    const navigate = useNavigate('/');
    const regex = /(<([^>]+)>)/ig;
const {
    
    isAuthenticated,
  } = useAuth0();


    useEffect(() => {
      fetch(`https://blakconscience.herokuapp.com/post/${id}`).then(response => {
            response.json().then(postInfo => {
              setPostInfo(postInfo);
            
            });
          });
      }, []);
    
      if (!postInfo) return navigate;



      const handleClick = async () => {
        await fetch(`https://blakconscience.herokuapp.com/post/${id}`, {
           method: 'DELETE'
       }).then(response => {
           response.json().then(postInfo => {
               setPostInfo(postInfo);
               
   
           })
               
               navigate('/')
       })
       
   
     }
    
    return (
        <>
    <SEO 
        title={postInfo.title}
        description={postInfo.summary}
        type="article"
        name={postInfo.author}/>
        <Flex
        minH={'100vh'}
        border={'none'}
        w={'70'}
        align={'center'}
        justifyContent={'center'}
        >
            <Box
            p={4}
            position={'relative'}
            justify={'space-evenly'}
            w={'100%'}
            display={'block'}
            >
                {isAuthenticated && (
                   <Box>
                   <Button as={Link} href={`/edit/${postInfo._id}`}>
                    Edit
                   </Button>
                   
                    <Button onClick={handleClick}>
                        Delete</Button>

                    </Box>
                )}
                <Box
                w={'100%'}  
                position={'relative'}
                margin={'auto'}
                p={2}
                >
                    <time>{format(parseISO(postInfo.createdAt), 'MMM-dd, yyyy')}</time>
                    <Heading
                    p={2}
                    margin={'auto'}
                    as='h3'
                    size={'lg'}
                    marginBottom={'auto'}>{postInfo.title}</Heading>
                
                <Box
                py={4}
                margin={'auto'}
                maxW={'100%'}
                w={'100%'}
                h={'auto'}
                 >
                    <Img
                    src={postInfo.image}
                    boxSize={'100%'}
                    overflow={'hidden'}
                    w={'100%'}
                    border={'4px'}/>
                </Box>
                <Spacer/>
                    <Box
                    marginTop={'10px'}
                    w={'100%'}
                    p={3}
                    >
                          <Text
                        fontSize={'2xl'}>{postInfo.summary}</Text>
                    </Box>
                    <Box
                    
                    
                    w={'100%'}
                    p={3}
                
                    >
                        <Box
                        fontSize='2xl'
                    
                         dangerouslySetInnerHTML={{__html:postInfo.content}} />
                    </Box>
                </Box>
            </Box>
        </Flex>
        </>    
    )
                }
