import {Box, Text, Link, Img, Heading, Flex, Time} from '@chakra-ui/react'
import {format, parseISO} from 'date-fns'
export default function BlogPostList({summary, author, cover, image, title, tag, _id, createdAt}) {
   
    return (
        
<Box margin={'auto'} maxW={600} w={[300,400,500,700, 600]} h={'100%'} border={'4px solid'} p='5'>
<time>{format(parseISO(createdAt), 'MMM-dd, yyyy')}</time>
    <Box>
        <Text as='b' fontSize='sm'>Author: Elijah Turner</Text>
    </Box>
        <Box top={'3em'} >
            <Heading as='h3' size='md'>
                <a  href={`/post/${_id}`} textDecoration="none" _hover={{ textDecoration: 'none' }}> {title}</a> </Heading>
        </Box>
        <Text as='i' >{tag}</Text>
        
            <Flex maxH={'100px'} >   
                <Box w={'100%'}>
                    <Text overflow={'hidden'} noOfLines={[2, 2, 3]}>
                    {summary}</Text>
                </Box>
                
                <Box bg='blue' w={[0,100,200,250]}>
                    <Img
                     src={`${image}`}
                    boxSize={'100%'}
                    maxW={'100%'}    
                    />
                </Box>
                
            </Flex>
    </Box>

    )   
    }