import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@chakra-ui/button";
import React from "react";

export default function LogoutButton() {

    const { logout } = useAuth0();

    return (
        <Button variant={'ghost'} onClick={() => logout({ logoutParams: { returnTo: window.location.origin }})}>
            Logout </Button>
    )
}