import {
    Box,
    Container,
    Input,
    Stack,
    Text,
    HStack,
    Button,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { Link } from 'react-router-dom';
  import { useAuth0 } from "@auth0/auth0-react";
  import LoginButton from './LoginButton';
  import LogoutButton from './LogoutButton';
  import emailjs from '@emailjs/browser'
  import { useRef, useState } from 'react';
  

  
  export default function SmallWithNavigation() {
    const {isAuthenticated} = useAuth0();
    const [text, setText] = useState('');

    const isValidEmail = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const form = useRef();


  
    const handleSubmit = (e) => {
      e.preventDefault();
    
      emailjs.sendForm('service_496770a', 'template_a11we1l', form.current, 'Ky2M87lJNVC7HVRmC')
      .then(function(response) {
      if (text && text.length && text.match(isValidEmail)) {
        window.alert("You have successfully subscribed!");
        console.log('Success!', response.status)
        setText('');
      } else {
        window.alert('Invalid Email')
        setText('')

      }
      }, function (error) {
        console.log(error)
      })
    }
    return (
      <Box 
        as={'footer'}
        bg={useColorModeValue('WhiteAlpha 900', 'BlackAlpha 900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Stack direction={'row'} spacing={6}>
            
            <a href={'/Subscribe'}>Subscribe</a>
            <Link as={Link} to={'/Search'}>Search</Link>
            

            {isAuthenticated && (
                  <LogoutButton/>
                )}
            {!isAuthenticated && (
                  <LoginButton/>
                )}
         
          </Stack>
          <HStack>
          <Text>Subscribe!</Text>
          <form onSubmit={handleSubmit} ref={form}>
          <Input
              type={'email'}
              value={text}
              onChange={(e) => setText(e.target.value)}
              focusBorderColor='lime'
              placeholder={'Email Address'}
              color={useColorModeValue('gray.800', 'gray.200')}
              bg={useColorModeValue('gray.100', 'gray.600')}
              rounded={'full'}
              border={0}
              _focus={{
                bg: useColorModeValue('gray.200', 'gray.800'),
                outline: 'none',
              }}
            />
            </form>
            <Button
                  onClick={handleSubmit}
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}>
                  Submit
            </Button>
          </HStack>
        </Container>
      </Box>
    );
  }